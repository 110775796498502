import useLowStock from 'public/src/pages/checkout/hooks/useLowStock.js'

// 利益点的一些信息
export default {
  // 低库存商品信息
  lowStockCartsInfo(state, getters) {

    const { lowStockProducts, isStockReason } = useLowStock({ 
      abt: getters.outOfStockTips || {}, 
      carts: state.checkout?.results?.carts?.carts || []
    })

    return {
      lowStockProducts: lowStockProducts.value,
      // true: 因库存满足条件; false: 仅因销售天数满足条件
      isStockReason: isStockReason.value
    }
  },
  // 最早到期的券
  earliestExpiryCoupon (state) {
    const now = Date.now()
    // 倒计时阈值可配置
    const threshold = +state.locals?.COUPON_COUNTDOWN_THRESHOLD || 48
    const maxOffset = threshold * 60 * 60 * 1000
    let coupon

    state.coupon.applyCouponsSuccess.forEach(item => {
      const originInfo = state.usableCouponList.find(coupon => coupon.coupon == item.couponCode)
      if (!originInfo) return
      // 排除会费券
      if ([58, 59].includes(+originInfo.real_type_id)) return
      let endTime = +originInfo.end_date
      if (endTime.toString().length < 13) endTime *= 1000
      const offset = endTime - now
      // 优先级：券到期时间越早，优先级越高
      const isEndFaster = !coupon || +originInfo.end_date < +coupon.originInfo.end_date
      if (offset > 0 && offset < maxOffset && isEndFaster) {
        coupon = { calculateInfo: item, originInfo }
      }
    })
    return coupon
  },
  // 30/60/90天的最低价
  lowestPriceBySomeDays(state, getters) {
    const cartsInfo = state.checkout?.results?.carts?.carts || []
    const lowestPrice = {}

    cartsInfo.forEach(val => {
      const showLowestPrice = val?.product?.showLowestPrice

      if(!!showLowestPrice){
        const days = showLowestPrice?.flag || 30 // 默认30，具体参考handleTspLabels => BehaviorLabel.getShowLabelForLowerPrice
        lowestPrice[days] = (lowestPrice[days] || []).concat(val)
      }
    })

    if (getters.lowestPriceDays?.lowest_price_days == 'new' && !!Object.keys(lowestPrice).length) {
      const minKey = Math.min(...(Object.keys(lowestPrice).map(key => parseInt(key))))
      
      return { days: minKey, lowestPriceData: lowestPrice[minKey] }
    }
    if (lowestPrice[30]) {
      return { days: 30, lowestPriceData: lowestPrice[30] }
    } 
    return { days: -1, lowestPriceData: [] }
  },
  // 付费会员节省金额
  primeClubPromotion(state) {
    const { cart_sub_infos = [] } = state.checkout?.mall_caculate_info || {}
    const cartsInfo = state.checkout?.results?.carts?.carts || []
    // 付费会员活动活动id=29，是否命中了活动is_promotion=1
    const isSatisfyActivity = cart_sub_infos.filter(_ => _.product_promotion_info.some(i => i.type_id == 29 && i.is_promotion == 1)) || []

    return cartsInfo?.filter(_ => isSatisfyActivity.some(i => i.cart_id == _.id))
  },
  // 超省卡节省金额
  saverCardPromotion(state) {
    let coupon = []
    const CouponTypeEnum = {
      PartFree: 'PartFreeCoupon', // 部分免邮券
      FreeShipping: 'FreeShippingCoupon', // 免邮券
      ImmediatelyDiscount: 'ImmediatelyDiscountCoupon', // 立减券
      Discount: 'DiscountCoupon', // 折扣券
    }

    state.coupon.applyCouponsSuccess.forEach(item => {
      const originInfo = state.usableCouponList.find(coupon => coupon.coupon == item.couponCode)
      const { real_type_id, apply_for, coupon_type_id, shipping_discount_type } = originInfo || {}
      
      if (real_type_id == 55 && [1, 2, 5].includes(Number(coupon_type_id))) {
        let type = ''
        if (apply_for == 9 && shipping_discount_type == 1) {
          type = CouponTypeEnum.PartFree // 部分免邮券
        } else if (apply_for == 9 && shipping_discount_type == 0) {
          type = CouponTypeEnum.FreeShipping // 免邮券
        } else if (coupon_type_id == 1) {
          type = CouponTypeEnum.ImmediatelyDiscount // 立减券
        } else {
          type = CouponTypeEnum.Discount // 折扣券
        }
        // type是当前的券类型，如果有多张的时候，在气泡中做优先级排序
        coupon.push({ type, usedCoupon: item, originInfo })
      }
    })

    return coupon
  },
  // 获取当前页面的利益点信息【针对下单按钮】
  orderBtnBenefitInfo(state, getters) {
    const btnBenefitMap = {
      FLASH_SALE: 'Flash_sale', // 普通闪购
      ORDER_DISCOUNT: 'order_discount', // 整单折扣
      LOW_STOCK: 'low_stock', // 低库存
      CHEAPER_THAN_ADDED_CART: 'cheaper_than_added_cart', // 商品相比加车降价
      LOWEST_PRICE: 'lowest_prices', // 最低价
      SECURE: 'Secure', // 支付安全
      REWARD_CREDIT: 'reward_credit', // 付费会员 credit 返还
    }
    const cartsInfo = state.checkout?.results?.carts?.carts || []

    return {
      [btnBenefitMap.FLASH_SALE]: !!state.promotionList?.find(_ => _.typeId == 10 && _.flashType == 1), // 普通闪购
      [btnBenefitMap.ORDER_DISCOUNT]: state.price?.total_discount > 0.2, // 整单折扣, 阈值是0.2(20%)
      [btnBenefitMap.LOW_STOCK]: !!getters.lowStockCartsInfo.lowStockProducts?.length, // 低库存
      [btnBenefitMap.CHEAPER_THAN_ADDED_CART]: !!cartsInfo.filter(_ => _.reduce_price?.reduceAmount?.amount > 0).length, // 商品相比加车降价	
      [btnBenefitMap.LOWEST_PRICE]: !!getters.lowestPriceBySomeDays.lowestPriceData.length, // 最低价
      [btnBenefitMap.SECURE]: true, // 支付安全，默认兜底，只要abt配置就会展示
      [btnBenefitMap.REWARD_CREDIT]: +state.price?.credit_total_amount?.amount > 0 && (state.isPrimeMember || !!state.usedPrimeCode), // 已经是付费会员或勾选了付费会员，且有 credit 返还
    }
  },
  // 获取当前下单按钮实际展示的利益点信息
  orderBtnActualBenefitInfo(state, getters) {
    if (!getters.placeOrderBarByAbt && getters.placeOrderBarByAbt != 'off') return []
    return getters.placeOrderBarByAbt.split(',').filter(i => getters.orderBtnBenefitInfo[i]) || []
  },
  // 联名卡积分，只判断站点支持，需要自行判断支付方式
  pointsFromCoBrandCard(state) {
    const { SiteUID } = state.locals || {}
    const { CO_BRANDED_CARD_SUPORT_SITE } = state.checkout

    // 判断当前站点是否支持联名卡
    const isSuportSite =
    CO_BRANDED_CARD_SUPORT_SITE &&
    SiteUID &&
    CO_BRANDED_CARD_SUPORT_SITE.includes(SiteUID)

    if(!isSuportSite) return 0

    let sheinPoints = 0
    // 未使用且有首单资格，加首单积分
    if (state.coBrandQualificationInfo) {
      const { has_used, qualification, first_order_point } =
        state.coBrandQualificationInfo
      const rewardPoints = state.price?.rewardPoints ?? 0
      if (has_used === 1) {
        sheinPoints = rewardPoints
      } else {
        sheinPoints = qualification == 1 ? rewardPoints + first_order_point : 0
      }
    }
    return sheinPoints
  }
}
