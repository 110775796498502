/**
 * 该文件放一些与abt相关的getter
 */
export default {
  // 是否展示辅助决策信息 => 已推全
  isShowSupportInfo(state){
    return state.checkoutAbtInfo.SupportInfo?.param?.SupportInfoShow === 'Show'
  },
  twJumpUrl(state){
    return state.checkoutAbtInfo.TaiwanJumpUrl?.param == 'TaiwanDownloadOn' ? true : false
  },
  // 是否展示 推荐券
  recommendCouponShowAbt(state){
    return state.checkoutAbtInfo.OrderPageCouponTest?.param?.RecommendCouponShow == 'Hide' ? true : false
  },
  // 是否展示付费会员可点击标识
  sheinclubStackableAbt(state){
    return state.checkoutAbtInfo.sheinclubStackable?.param == 'notsupportSheinclubStackable' ? false : true
  },
  // 开启总优惠金额展示
  showSaveAmount(state){
    return state.checkoutAbtInfo.Shownewsaveamount?.param?.show_new_save_amount == 'on' ? true : false
  },
  // 是否开启税费abt
  isShowTotalFee(state){
    return state.checkoutAbtInfo.TotalFee?.param == 'ShowTotalFee' ? true : false
  },
  // 是否展示时效百分比
  timePercentAbt(state){
    return state.checkoutAbtInfo.TimePercent?.param?.['show_shippingpercentage'] == 0 ? false : true
  },
  // abt开启快速运输
  showQuickShipByAbt(state, getters){
    return getters.isSiteMallList ? false : state.checkoutAbtInfo.TradeQuickship?.p == 'show_QuickShipping' ? true : false
  },
  // 购物袋入口位置
  shoppingBagPosition(state, getters){
    return getters.isSiteMallList ? true : state.checkoutAbtInfo.Shoppingbagposition?.param?.shoppingbag_position == 'old' ? false : true
  },
  // 沙特高货值信息必填abt
  completeHighValueInformation(state){
    return state.checkoutAbtInfo.CompleteHighvalueInformation?.param || {}
  },
  isShowbrTaxhint(state){
    return state.checkoutAbtInfo.brTaxhint?.param?.isSwitchOn == 1 ? true : false
  },
  isNewPrimeVersion(state) {
    return state.checkoutAbtInfo.OrderEntryprimeVersion?.param?.primeVersion == 'new' ? true : false
  },
  isShowPriceReminderByAbt(state) {
    return state.checkoutAbtInfo?.PriceReminder?.param?.PriceReminderswitch == 'show' ? true : false
  },
  orderInterceptByAbt(state) {
    return state.checkoutAbtInfo.OrderInterceptionRules?.param?.InterceptionRulesStatus == 'on' ? true : false
  },
  forcedShippingByAbt(state) {
    return state.checkout?.abtInfo?.forcedShipping?.param == 'forcedShippingGuarantee'
  },
  switchQsFlagByAbt(state, getters) {
    let switch_qs_flag = 0
    const qsMakeup = state.checkout?.abtInfo?.quickshipFeeSwitch?.param?.qsMakeup || 0
  
    const singleMallCode = state.checkout?.results?.mallInfo?.mallInfoForSite?.[0]?.mall_code || ''
    const filterCarts = state.checkout?.results?.carts?.carts?.filter(item => item.mall_code == singleMallCode) || []
    const quickShipCarts = filterCarts?.filter(item => item.real_quick_ship == 1) || []
    const isAllQuickShip = !!quickShipCarts.length && (filterCarts.length == quickShipCarts.length)
  
    if (getters.showQuickShipByAbt && !isAllQuickShip) switch_qs_flag = qsMakeup
  
    return switch_qs_flag
  },
  isNewCheckoutCouponlist(state) {
    return state.checkout?.abtInfo?.CheckoutNewCouponlist?.param?.checkout_new_couponlist == 'on'
  },

  userBehaviorAbt(state) {
    return state.checkout?.abtInfo?.UserBehaviorTips?.param || {}
  },
  lowestPriceDays(state) {
    return state.checkout?.abtInfo?.LowestPriceDays?.param || {}
  },
  showShippingInHeaderTitleByAbt(state) {
    // 非US 如果abt 没返回值或者abt异常默认值改成off
    return state.checkout?.abtInfo?.CheckoutHeadShippingEffect?.param.CheckoutHeadShippingEffect || 'off' 
  },
  canEditShoppingbag(state) {
    if(state.locals?.IS_RW) return false
    return state.checkout?.abtInfo?.editShoppingbag?.param?.editstatus == '1'
  },
  showOrderRulesByAbt(state) {
    if(state.locals?.IS_RW) return false
    return true
  },
  showOrderBubbleSort(state) {
    const sortList = state.checkout?.abtInfo?.placeOrderbubblesort?.param?.placeOrderbubblesortvalue || 'promotion,inventory,coupon'
    return sortList.toLocaleLowerCase().replace(/\s/g, '').split(',')
  },
  underPriceAbtValue (state) {
    return state.checkout?.abtInfo?.UnderPrice?.param?.UnderPriceShow == 'A' ?  true : false
  },
  autoRenewalPrimeShowAbt(state) {
    return state.checkout?.abtInfo?.sheinClubRenew?.param?.sheinClubRenewStatus == 1
  },
  shoppingBagCrossedPriceByAbt(state) {
    return state.checkout?.abtInfo?.Shoppingbagcrossedprice?.param?.add_shoppingbag_price_switch != 'off'
  },
  discountLogoByAbt(state) {
    return state.checkout?.abtInfo?.Discountlogo?.param?.add_discount_logo_switch != 'off'
  },
  orderConfirmDiscountSwitchByAbt(state) {
    const SiteUID = state.locals?.SiteUID || gbCommonInfo?.SiteUID || ''
    const isBrSite = !!~SiteUID.indexOf('br')

    if(state.locals?.IS_RW || isBrSite) return false
    // abt全推 orderConfirmDiscountSwitchStatus 写死等于1 不受abt控制
    return true
  },
  placeorderStatusmoveByAbt(state) {
    const SiteUID = state.locals?.SiteUID || gbCommonInfo?.SiteUID || ''
    const isBrSite = !!~SiteUID.indexOf('br')

    if(state.locals?.IS_RW || isBrSite) return false
    // 
    return true
  },
  saverNewStyleAbt(state) {
    return state.checkout?.abtInfo?.saverNewOrderStyle?.param?.saverNewOrderStyleStatus == 1
  },
  // 下单页面是否使用新版本按钮布局样式
  isShowNewFooterLayoutStyle(state) {
    // abt 全推代码写死 placeOrderButtonUpgradeStatus = 1
    return !state.locals?.IS_RW 
  },
  shoppingBagTypeAbt() {
    // abt全推  ShoppingBagType 写死Item_Detail
    return true
  },
  showAddEntranceByAbt(state, getters){
    return getters.isSiteMallList ? false : state.checkout?.abtInfo?.CheckoutShippingAddEntracne?.param?.checkout_shipping_add_entracne_switch == 'on'
  },
  // 下单页面凑单入口是否展示新逻辑
  showNewAddEntranceByAbt(state){
    return state.checkout?.abtInfo?.CheckoutShippingAddEntracne?.param?.checkout_shipping_add_entracne_strategy == 'on'
  },
  shippingTipEnhanceByAbt(state){
    return state.checkout?.abtInfo?.CheckoutShippingTipEnhance?.param?.checkout_shipping_tip_enhance_switch == 'on'
  },
  clubScribePriceByAbt(state) {
    if(state.locals?.IS_RW) return false

    const SiteUID = state.locals?.SiteUID || gbCommonInfo?.SiteUID || ''
    // 法国、波兰、德国站点
    return state.checkout?.abtInfo?.ClubScribePrice?.param?.ClubScribePrice == 'noshow' && ['pwfr', 'mpl', 'pwde'].includes(SiteUID)
  },
  shippingSlideByAbt(state) {
    return state.checkout?.abtInfo?.shippingVersion?.param?.isShippingVersion == 'new'
  },
  showNewCouponLanguage(state) {
    return state.checkout?.abtInfo?.Placeordercoupon?.param?.Placeordercouponvalue == 1
  },
  // 无地址（宅配）的时候，需要进入下单页面后才填写地址
  isNoAddressEnterCheckoutByAbt(state) {
    return !state.locals?.IS_RW && ['typeA', 'typeB', 'typeC'].includes(state.checkout?.abtInfo?.NoAddressInteract?.param?.NoAddressInteract)
  },
  // 是否开启evoluSHEIN标签
  switchOnEvoluSHEIN(state) {
    return state.checkout?.abtInfo?.evoluSHEINShow?.param?.evoluSHEIN_show === 'on'
  },
  sheinClubUIByAbt(state) {
    const SiteUID = state.locals?.SiteUID || gbCommonInfo?.SiteUID || ''
   
    let res = 1
    switch(SiteUID){
      case 'pwar': // 只有AR 站是2 其他的目前都是1
        res = 2
        break
    }
    return res
    // return state.checkout?.abtInfo?.Ordersheinclub?.param?.sheinclubui || 0
  },
  saverHeightByAbt(state) {
    return state.checkout?.abtInfo?.saverHeight?.param?.saverHeightStatus == 1
  },
  showArrivalPriceByAbt(state) {
    const priceOrderArrivalStatus = state.checkout?.abtInfo?.priceOrderArrival?.param?.priceOrderArrivalStatus || 1
    return priceOrderArrivalStatus == 1
  },
  storeIconChangeByAbt(state) {
    return state.checkout?.abtInfo?.storeiconchange?.param?.storeiconchange == 'new'
  },
  // 是否支持卡分期前置判断 目前仅支持卡分期
  isUsePrePayForCheckout(state, getter) {
    if (getter.isSelectInstallmentTokenPay) return false
    return state.status.cardPay === 'routepay-cardinstallment' && 
      state.checkout?.abtInfo?.cardinstallmentPaymentFront?.param?.cardinstallmentPaymentFrontStatus == 1
  },

  // 卡分期是否需要二次check
  isCheckCardinstallmentInfoStatus(state, getter) {
    if (getter.isSelectInstallmentTokenPay) return false
    return state.status.cardPay === 'routepay-cardinstallment' && 
      state.checkout?.abtInfo?.checkCardinstallmentInfo?.param?.checkCardinstallmentInfoStatus == 1 // 卡分期需要二次check
  },
  // 控制气泡展示的时机和气泡的文案
  bubbleCopyWritingByAbt(state) {
    // BubbleCopywriting abt 没返回或者abt 意思默认为on
    const BubbleCopywriting = state.checkout?.abtInfo?.BubbleCopywriting?.param?.BubbleCopywriting || 'on'
    return BubbleCopywriting == 'on'
  },
  placeOrderBarByAbt(state) {
    return state.checkoutAbtInfo?.PlaceOrderBar?.param?.PlaceOrderBar
  },
  isShowNewActivityPopverLayoutByAbt(state) {
    return state.checkout?.abtInfo?.BubbleStyle?.param?.BubbleStyle == 'new'
  },
  defaultLocalSizeByAbt(state) {
    return state.checkout?.abtInfo?.defaultlocalsize?.param?.defaultlocalsize || ''
  },
  outOfStockTips (state) {
    return state.checkout?.abtInfo?.outofstocktips?.param || {}
  },
  shoppingBagTipsPriority(state) {
    const abt = state.checkout?.abtInfo?.CheckoutShoppingBag?.param?.ShoppingBagTips || 'Hide'
    if (!abt || abt === 'Hide') return []
    return abt.split(',')
  },
  shoppingBagBeltPriority(state) {
    const abt = state.checkout?.abtInfo?.CheckoutShoppingBag?.param?.ShoppingBagItems || 'Hide'
    if (!abt || abt === 'Hide') return []
    return abt.split(',')
  },
  showCartRefundByAbt(state) {
    return state.checkout?.abtInfo?.Cartrefund?.param?.cart_nonrefundable == 'on'
  },
  showRRPByAbt(state) {
    return state.checkout?.abtInfo?.TradeRrpPrice?.param?.trade_rrp_price == 'on'
  },
  // 无地址进入下单页面的时候，判断是跳转地址填写页面还是跳转地址填写弹框
  isJumpAddressPopStyleByAbt(state) {
    return state.checkout?.abtInfo?.JumpAddressStyle?.param.JumpAddressStyle == 'Pop'
  },
  CartOrderSFSVisual(state) {
    return {
      visual_tabs: state.checkout?.abtInfo?.CartOrderSFSVisual?.param?.visual_tabs == 'show_tabs',
      visual_benefit_point: state.checkout?.abtInfo?.CartOrderSFSVisual?.param?.visual_benefit_point == 'show_benefit_point'
    }
  },
}
